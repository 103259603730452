.animal-show{
    position: relative;
    border: 1px solid lightgray;
    border-radius: 5px;
    box-shadow: 0px 3px 4px lightgray;
    margin: 2%;
    padding: 2%;
}

.animal{
    width: 200px;
}
.heart{
    position: absolute;
    bottom:10%;
    right : 10%;
}