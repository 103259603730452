.animal-list{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
}

.forCenter{
    justify-content: center;
    display: flex;
    font-weight: bold;
}
